import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
    createListenerMiddleware,
    isAnyOf,
  } from '@reduxjs/toolkit'
  
  
  import authReducer from './slices/authSlice'
  import positionReducer from './slices/positionSlice'
  import positionFilterReducer from "./slices/positionFilterSlice"
  import sequenceReducer from "./slices/SequenceSlice"
  import sequenceFormReducer from "./slices/SequenceFormSlice"
  import sequenceFilterReducer from "./slices/sequenceFilterSlice"
  import philosophyReducer from "./slices/philosophySlice"
  import contentfulReducer from "./slices/contentfulSlice";
  import positionFormReducer from "./slices/positionFormSlice"
  import commonReducer from "./slices/commonSlice";
  import userReducer from "./slices/userSlice";
  import registerReducer from "./slices/registerSlice";
  import paymentReducer from "./slices/paymentSlice";
  import payment2Reducer from "./slices/payment2Slice";
  
  const combinedReducer = combineReducers({

    auth: authReducer,
    common: commonReducer,
    positions: positionReducer,
    positionFilter: positionFilterReducer,
    sequence: sequenceReducer,
    sequenceForm: sequenceFormReducer,
    sequenceFilter: sequenceFilterReducer,
    philosophy: philosophyReducer,
    contentful: contentfulReducer,
    positionForm: positionFormReducer,
    user: userReducer,
    register: registerReducer,
    payment: paymentReducer,
    payment2: payment2Reducer,
  })
  
  const listenerMiddleware = createListenerMiddleware()
  
  
  export const store = configureStore({
    reducer: combinedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(),
    devTools: process.env.NODE_ENV === 'development',
  })
  
  export type RootState = ReturnType<typeof store.getState>
  
  /* eslint-disable */
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    any,
    Action<string>
  >
  