/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InitPaymentResponseDto } from '../models/InitPaymentResponseDto';
import type { ProductDto } from '../models/ProductDto';
import type { SessionDto } from '../models/SessionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static postApiPaymentStripe(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/stripe',
        });
    }

    /**
     * @returns boolean OK
     * @throws ApiError
     */
    public static postApiPaymentSync(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/sync',
        });
    }

    /**
     * @returns ProductDto OK
     * @throws ApiError
     */
    public static getApiPaymentProducts(): CancelablePromise<Array<ProductDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/products',
        });
    }

    /**
     * @param priceId 
     * @param userId 
     * @param returnPath 
     * @returns InitPaymentResponseDto OK
     * @throws ApiError
     */
    public static postApiPaymentCreateCheckoutSession(
priceId?: number,
userId?: number,
returnPath?: string,
): CancelablePromise<InitPaymentResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/create-checkout-session',
            query: {
                'priceId': priceId,
                'userId': userId,
                'returnPath': returnPath,
            },
        });
    }

    /**
     * @param sessionId 
     * @returns SessionDto OK
     * @throws ApiError
     */
    public static getApiPaymentSessionStatus(
sessionId?: string,
): CancelablePromise<SessionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/session-status',
            query: {
                'sessionId': sessionId,
            },
        });
    }

    /**
     * @param userId 
     * @param entryId 
     * @returns ProductDto OK
     * @throws ApiError
     */
    public static postApiPaymentSendMail(
userId?: number,
entryId?: string,
): CancelablePromise<Array<ProductDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/sendMail',
            query: {
                'userId': userId,
                'entryId': entryId,
            },
        });
    }

    /**
     * @param userId 
     * @returns InitPaymentResponseDto OK
     * @throws ApiError
     */
    public static postApiPaymentCreateCheckoutSession1(
userId?: number,
): CancelablePromise<InitPaymentResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/createCheckoutSession',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param sessionId 
     * @returns SessionDto OK
     * @throws ApiError
     */
    public static getApiPaymentCheckSessionStatus(
sessionId?: string,
): CancelablePromise<SessionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/checkSessionStatus',
            query: {
                'sessionId': sessionId,
            },
        });
    }

}
