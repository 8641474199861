import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Button,
  CardContent,
  Alert,
  Typography,
  Chip,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import {
  cancelSubscriptionAsync,
  payment2StateSelector,
  resumeSubscriptionAsync,
} from "store/slices/payment2Slice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useBoolean } from "api/hooks/use-boolean";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import { getDatePartFromString, getOnlyDateFromString } from "utils/dateUtils";
import { SubscriptionDto } from "api";
import { useLocales } from "locales";
import { getValidSubscription, SubscriptionStatus } from "types/Subscription";

interface SubscriptionCardProps {}

const SubscriptionCard = ({}: SubscriptionCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useLocales();

  const [subscription, setSubscription] = useState<SubscriptionDto | null>(
    null
  );

  const { hasError, error } = useSelector(payment2StateSelector);

  const openCancel = useBoolean();

  const { currentUser } = useSelector(authStateSelector);

  useEffect(() => {
    const validSubscription = getValidSubscription(currentUser!);
    if (validSubscription) {
      console.log(validSubscription);
      setSubscription(validSubscription);
    }
  }, [currentUser]);

  const handleCancelSubscription = useCallback(() => {
    dispatch<any>(cancelSubscriptionAsync());
  }, [dispatch]);

  const handleResumeSubscription = useCallback(() => {
    dispatch<any>(resumeSubscriptionAsync());
  }, [dispatch]);

  const renderTrailingInfo = () => {
    if (subscription?.status === SubscriptionStatus.trialing)
      return (
        <Chip
          label={`${t("trailPeriodEnd")} ${getDatePartFromString(
            subscription?.trailEndAt!
          )}`}
          size="small"
          sx={{ marginLeft: "10px" }}
        />
      );
  };

  const renderActiveInfo = () => {
    if (subscription?.status === SubscriptionStatus.active)
      return (
        <Chip
          label={`${t("active")}`}
          size="small"
          color="primary"
          sx={{ marginLeft: "10px" }}
        />
      );
  };

  const renderCancelAtInfo = () => {
    if (
      subscription?.status === SubscriptionStatus.trialing &&
      subscription.canceledAt != null
    )
      return (
        <Chip
          label={`${t("cancelAt")} ${getDatePartFromString(
            subscription?.cancelAt!
          )}`}
          size="small"
          sx={{ marginLeft: "10px" }}
          color="info"
        />
      );
  };

  const renderCanceledInfo = () => {
    if (subscription?.status === SubscriptionStatus.canceled)
      return (
        <Chip
          label={`${t("dismissed")} `}
          size="small"
          sx={{ marginLeft: "10px" }}
        />
      );
  };

  const renderCancelButton = () => {
    if (
      (subscription?.status === SubscriptionStatus.trialing ||
        subscription?.status === SubscriptionStatus.active) &&
      !subscription.cancelAt
    )
      return (
        <Button variant="contained" color="error" onClick={openCancel.onToggle}>
          {t("abortSubscription")}
        </Button>
      );
  };

  const renderWakeUpButton = () => {
    if (
      (subscription?.status === SubscriptionStatus.trialing ||
        subscription?.status === SubscriptionStatus.active) &&
      subscription.canceledAt != null
    )
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleResumeSubscription()}
        >
          {t("wakeUpSubscription")}
        </Button>
      );
  };

  const renderSelectSubscriptionButton = () => {
    if (subscription === null )
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/subscription")}
        >
          {t("selectSubscription")}
        </Button>
      );
  };

  const renderSubscriptionData = () => {
    if (subscription)
      return (
        <Stack
          flexDirection="row"
          spacing={1.5}
          direction="row"
          justifyContent="flex-end"
        >
          <Typography sx={{ fontSize: 9, fontStyle: "italic" }}>
            {subscription?.externalId}
          </Typography>
        </Stack>
      );
  };

  const renderSubscriptionInfo = () => {
    return (
      <Stack spacing={2} sx={{ p: 1, pt: 0, typography: "body2" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
            {t("product")}
          </Grid>
          <Grid item xs={12} md={8} sx={{ typography: "h3" }}>
            <Stack flexDirection={"row"}>
              <Typography>
                {subscription && subscription.isActive
                  ? subscription.productName
                  : "-"}
              </Typography>
              {renderActiveInfo()}
              {renderTrailingInfo()}
              {renderCancelAtInfo()}
              {renderCanceledInfo()}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
            {t("subscriptionStart")}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ typography: "subtitle2", textTransform: "capitalize" }}
          >
            {subscription && subscription.isActive
              ? getDatePartFromString(subscription.currentPeriodStart)
              : "-"}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
            {t("subscriptionEnd")}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ typography: "subtitle2", textTransform: "capitalize" }}
          >
            {subscription && subscription.isActive
              ? getDatePartFromString(subscription.currentPeriodEnd)
              : "-"}
          </Grid>
        </Grid>
        {!subscription?.isCanceled && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} sx={{ color: "text.secondary" }}>
              <Typography sx={{ fontStyle: "italic" }} variant="body2">
                {t("renewalSubscription")}
              </Typography>
            </Grid>
          </Grid>
        )}
        {subscription?.isCanceled && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
              {t("subscriptionCanceled")}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ typography: "subtitle2", textTransform: "capitalize" }}
            >
              {subscription
                ? getDatePartFromString(subscription.canceledAt!)
                : "-"}
            </Grid>
          </Grid>
        )}
        {subscription?.isCanceled && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
              {t("subscriptionLastDay")}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ typography: "subtitle2", textTransform: "capitalize" }}
            >
              {subscription
                ? getDatePartFromString(subscription.cancelAt!)
                : "-"}
            </Grid>
          </Grid>
        )}
        <Divider />
        {renderSubscriptionData()}
      </Stack>
    );
  };

  const renderNoSubscription = () => {
    return <Alert variant="soulwise">{t("subscriptionMandatory")}</Alert>;
  };

  return (
    <>
      <Card>
        <CardHeader title={t("subscription")} sx={{ mb: "10px" }} />
        {hasError && (
          <Alert severity="error" sx={{ margin: "5px" }}>
            {error}
          </Alert>
        )}
        <CardContent>
          {subscription ? renderSubscriptionInfo() : renderNoSubscription()}
        </CardContent>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Stack
          spacing={1.5}
          direction="row"
          justifyContent="flex-end"
          sx={{ p: 3 }}
        >
          {renderCancelButton()}
          {renderSelectSubscriptionButton()}
          {renderWakeUpButton()}
        </Stack>
      </Card>
      <CancelSubscriptionDialog
        open={openCancel.value}
        onClose={openCancel.onFalse}
        onCancelSubscription={handleCancelSubscription}
      />
    </>
  );
};

export default SubscriptionCard;
