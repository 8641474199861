import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Alert, Step, StepButton, Stepper } from "@mui/material";
import RegisterForm from "components/forms/RegisterForm";
import { Box, Container, Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import Payment2Form from "components/forms/payment/Payment2Form";
import {
  payment2StateSelector,
  setActiveStep,
  setPriceId,
  setSessionId,
  StepType,
} from "store/slices/payment2Slice";
import PaymentSummary from "components/forms/payment/PaymentSummary";
import { useLocales } from "locales";

export const Register2View = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useLocales();

  const steps = [
    t("createAccount"),
    t("pay"),
    t("compilation"),
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const { activeStep, selectedPriceId, hasError, error } =
    useSelector(payment2StateSelector);

  useEffect(() => {
    const step = searchParams.get("step");
    const sessionId = searchParams.get("session_id");
    console.log(step);
    console.log(sessionId);
    if (sessionId) {
      dispatch(setSessionId(sessionId));
      dispatch(setActiveStep(StepType.Summary));
    } else if (step) dispatch(setActiveStep(parseInt(step)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStep = (step: number) => () => {
    dispatch(setActiveStep(step));
  };

  const handleUserCreated = () => {
    dispatch(setActiveStep(StepType.Pay));
  };

  return (
    <Container maxWidth={"md"}>
      <Stack>
        <Box
          sx={{
            mt: "50px",
            mb: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            height={56}
            src={"/images/soulwisewrapperlogo.png"}
            alt="soulwise yoga"
          />
        </Box>
        {hasError && (
          <Alert severity="error" variant="outlined" sx={{ mb: "20px" }}>
            {error}
          </Alert>
        )}
        <Stepper activeStep={activeStep} sx={{ mb: "20px" }} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === 0 && <RegisterForm onUserCreated={handleUserCreated} />}
        {activeStep === 1 && <Payment2Form type="register" />}
        {activeStep === 2 && <PaymentSummary />}
      </Stack>
    </Container>
  );
};
