import React, { useEffect, useState } from "react";
import { StdView } from "./StdView";
import { useNavigate } from "react-router-dom";
import { Container, Stack } from "@mui/system";
import { Typography } from "@mui/material";

import { useLocales } from "locales";
import { useDispatch } from "react-redux";
import {
  checkPaymentStatusAsync,
  payment2StateSelector,
} from "store/slices/payment2Slice";
import { useSelector } from "react-redux";
import { PaymentStatus } from "types/Subscription";

const Payment2View = () => {
  const dispatch = useDispatch<any>();
const navigate = useNavigate();
  const { t } = useLocales();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");
  const [timer, setTimer] = useState<NodeJS.Timer>();

  const { paymentSession } = useSelector(payment2StateSelector);

  console.log(`paymentSession: ${paymentSession}`);

  useEffect(() => {
    if (paymentSession?.status === PaymentStatus.complete) {
      clearInterval(timer);
      if(paymentSession?.type === "subscription")
        navigate("/user/account?tabid=1")

      if(paymentSession?.type === "register")
        navigate("/register?step=2")
    }
  }, [paymentSession]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkPaymentStatusAsync(sessionId!));
      console.log("check status");
    }, 1000);

    setTimer(interval);

    return () => clearInterval(interval);
  }, []);

  return (
    <StdView title={t("compilation")} nosubscription>
      <Container maxWidth={"lg"}>
        {paymentSession?.status !== PaymentStatus.complete && (
          <Stack>
            <Typography variant="h1">{`${t('checkStatus')}...`}</Typography>
          </Stack>
        )}
      </Container>
    </StdView>
  );
};

export default Payment2View;
