import React, { useEffect } from "react";
import { StdView } from "./StdView";
import { Container } from "@mui/system";
import Payment2Form from "components/forms/payment/Payment2Form";
import { useDispatch } from "react-redux";
import { useLocales } from "locales";

const Subscription2View = () => {
  const { t } = useLocales();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StdView title={t("chooseSubscription")} nosubscription>
      <Container maxWidth={"lg"}>
        <Payment2Form type="subscription" />
      </Container>
    </StdView>
  );
};

export default Subscription2View;
