import { Alert, Avatar, Box, Button, Typography } from "@mui/material";
import { UserDto } from "api";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import * as yup from "yup";
import AppTextField from "./AppTextField";
import { SoulwisePaper } from "components/papers/SoulwisePaper";
import Grid from "@mui/material/Grid2";
import {
  onCreateUserImage,
  onDeleteUserImage,
  updateUserAsync,
} from "store/slices/userSlice";
import isEmailValidator from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import { fData } from "utils/format-number";
import UploadAvatar from "components/upload/UploadAvatar";
import { Stack } from "@mui/system";
import UserDeleteImageDialog from "components/profile/UserDeleteImageDialog";
import { useLocales } from "locales";

export interface ProfileFormProps {
  user: UserDto;
}

const ProfileForm = ({ user }: ProfileFormProps) => {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const [showError, setShowError] = useState(false);

  const { error } = useSelector(authStateSelector);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("enterValidEmail"))
      .required(t("currentEmail"))
      .test("is-valid", t("enterValidEmail"), (value) =>
        value
          ? isEmailValidator(value)
          : new yup.ValidationError(t("enterValidEmail"))
      ),
    instagramUri: yup
      .string()
      .test("is-valid", t("validUrl"), (value) =>
        value ? isURL(value) : new yup.ValidationError(t("validUrl"))
      ),
    facebookUri: yup
      .string()
      .test("is-valid", t("validUrl"), (value) =>
        value ? isURL(value) : new yup.ValidationError(t("validUrl"))
      ),
    webUri: yup
      .string()
      .test("is-valid", t("validUrl"), (value) =>
        value ? isURL(value) : new yup.ValidationError(t("validUrl"))
      ),
  });

  const formik = useFormik({
    initialValues: {
      id: user ? user.id : 0,
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      about: user ? user.about : "",
      email: user ? user.email : "",
      city: user ? user.city : "",
      phoneNumber: user ? user.phoneNumber : "",
      instagramUri: user && user.instagramUri ? user.instagramUri : "",
      facebookUri: user && user.facebookUri ? user.facebookUri : "",
      webUri: user && user.webUri ? user.webUri : "",
      imageUri: user ? user.imageUri : "",
      location: user ? user.location : "",
    } as UserDto,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setShowError(false);
      console.log("User", values);
      try {
        const updatedUser = {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          about: values.about,
          email: values.email,
          city: values.city,
          phoneNumber: values.phoneNumber,
          instagramUri: values.instagramUri,
          facebookUri: values.facebookUri,
          webUri: values.webUri,
          location: values.location,
        } as UserDto;
        const result = await dispatch<any>(updateUserAsync(updatedUser, t('savingSuccess')));
        if (!result) setShowError(true);

        if (values.imageUri && typeof values.imageUri !== "string") {
          await dispatch<any>(onCreateUserImage(values.id!, values.imageUri, t('savingSuccess')));
        }
      } catch (error) {
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue("imageUri", newFile);
        console.log("handledrop2", formik.values);
      }
    },
    [formik.setFieldValue]
  );

  async function handleDeleteImage(): Promise<void> {
    await dispatch<any>(onDeleteUserImage(user.id!, t('savingSuccess')));
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid
          container
          spacing={3}
          sx={{
            mb: { xs: 6, sm: 0 },
            textAlign: { xs: "center", sm: "left" },
            border: 0,
          }}
        >
          <Grid size={{ xs: 12, md: 4 }}>
            <SoulwisePaper>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Field name="imageUri">
                  {({}) => (
                    <UploadAvatar
                      maxSize={3145728}
                      onDrop={handleDrop}
                      file={formik.values.imageUri}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 3,
                            mx: "auto",
                            display: "block",
                            textAlign: "center",
                            color: "text",
                          }}
                        >
                          {t("allowedFormat")}
                          <br /> {t("maxSize")} {fData(3145728)}
                        </Typography>
                      }
                    />
                  )}
                </Field>

                <UserDeleteImageDialog
                  user={user}
                  disabled={user && user.imageUri! === null}
                />
              </Stack>
            </SoulwisePaper>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <SoulwisePaper>
              <Grid container spacing={2} sx={{}}>
                <Grid
                  size={{ xs: 12, sm: 6 }}
                  sx={{ textAlign: { xs: "left" } }}
                >
                  <AppTextField
                    color="primary"
                    fullWidth
                    required
                    name="firstName"
                    value={
                      formik.values.firstName ? formik.values.firstName : ""
                    }
                    title={t("firstName")}
                    inputProps={{ maxLength: 50 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid
                  size={{ xs: 12, sm: 6 }}
                  sx={{ textAlign: { xs: "left" } }}
                >
                  <AppTextField
                    color="primary"
                    fullWidth
                    required
                    name="lastName"
                    value={formik.values.lastName ? formik.values.lastName : ""}
                    title={t("lastName")}
                    inputProps={{ maxLength: 50 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "left" } }}>
                  <AppTextField
                    color="primary"
                    fullWidth
                    name="city"
                    value={formik.values.city ? formik.values.city : ""}
                    title={t("city")}
                    inputProps={{ maxLength: 50 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "left" } }}>
                  <AppTextField
                    color="primary"
                    fullWidth
                    name="location"
                    value={formik.values.location ? formik.values.location : ""}
                    title={t("location")}
                    inputProps={{ maxLength: 150 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "left" } }}>
                  <AppTextField
                    color="primary"
                    fullWidth
                    name="instagramUri"
                    value={
                      formik.values.instagramUri
                        ? formik.values.instagramUri
                        : ""
                    }
                    title="Instagram"
                    inputProps={{ maxLength: 100 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "left" } }}>
                  <AppTextField
                    color="primary"
                    fullWidth
                    name="facebookUri"
                    value={
                      formik.values.facebookUri ? formik.values.facebookUri : ""
                    }
                    title="Facebook"
                    inputProps={{ maxLength: 100 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "left" } }}>
                  <AppTextField
                    color="primary"
                    fullWidth
                    name="webUri"
                    value={formik.values.webUri ? formik.values.webUri : ""}
                    title={t("web")}
                    inputProps={{ maxLength: 150 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "left" } }}>
                  <AppTextField
                    color="primary"
                    type="email"
                    fullWidth
                    required
                    name="email"
                    value={formik.values.email ? formik.values.email : ""}
                    title={t("email")}
                    inputProps={{ maxLength: 100 }}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "left" } }}>
                  <AppTextField
                    color="primary"
                    fullWidth
                    multiline
                    rows={4}
                    name="about"
                    value={formik.values.about ? formik.values.about : ""}
                    title={t("about")}
                    inputProps={{ maxLength: 500 }}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid
                  size={12}
                  sx={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={formik.isSubmitting}
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </SoulwisePaper>
          </Grid>

          <Grid size={12}>
            {showError && (
              <Alert severity="error">
                {t("saveError")} {error}
              </Alert>
            )}
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ProfileForm;
