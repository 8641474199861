import { SubscriptionDto, UserDto } from "api";
import { getOnlyDateFromString } from "utils/dateUtils";

export enum SubscriptionStatus {
  "incomplete" = "incomplete",
  "trialing" = "trialing",
  "active" = "active",
  "canceled" = "canceled",
  "unpaid" = "unpaid",
  "paused" = "paused",
}

export enum PaymentStatus {
    "complete" = "complete",
  }

export const hasValidSubscription = (user: UserDto) => {
  if (user === null || user.subscriptions === null || user.subscriptions === undefined || user?.subscriptions.length === 0) return false;

  const currentDate = new Date();

  const validSubscription = getValidSubscription(user)

  return validSubscription !== null;
};

export const getValidSubscription = (user: UserDto): SubscriptionDto | null => {
  if (user === null || user.subscriptions === null || user.subscriptions === undefined || user?.subscriptions.length === 0) return null;

  const currentDate = new Date();

  const validSubscription = user.subscriptions.filter(
    (subscription) =>
      getOnlyDateFromString(subscription.currentPeriodStart!)! <= currentDate &&
      getOnlyDateFromString(subscription.currentPeriodEnd!)! >= currentDate &&
      (subscription.status === SubscriptionStatus.active ||
        subscription.status === SubscriptionStatus.trialing)
  );

  return validSubscription[0];
};
