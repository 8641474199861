import { Button, Snackbar, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StdView } from "views/OtherViews/StdView";
import { authStateSelector } from "store/slices/authSlice";
import { Link } from "react-router-dom";
import { AdminService, PaymentService } from "api";
import Grid from "@mui/material/Grid";

const AdminView = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(authStateSelector);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [syncInProgress, setSyncInProgress] = useState(false);

  useEffect(() => {}, [dispatch]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setNewVersionAvailable = async () => {
    try {
      const result = await AdminService.postApiAdminSetnewversion();
      setMessage("Ny version aktiverad");
      setOpen(true);
    } catch (error) {}
  };

  const syncronizeStripeProducts = async () => {
    try {
      setSyncInProgress(true)
      const result = await PaymentService.postApiPaymentSync();
      console.log(result)
      if (result) {
        setMessage("Abonnemang, Produkter och Priser synkroniserade med Stripe");
        setOpen(true);
      } else {
        setMessage("Ett fel uppstod vid synkronisering");
        setOpen(true);
      }
    } catch (error) {
      setMessage("Ett fel uppstod vid synkronisering");
      setOpen(true);
      console.log(error)
    }
    finally {
      setSyncInProgress(false)
    }
  };

  return (
    <StdView title={"Admin"}>
      {currentUser?.isAdmin! ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            Visar bannern med att en ny version är publicerad
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={setNewVersionAvailable}
              variant="contained"
              sx={{ width: 300, mb: 2 }}
            >
              Notifiera om ny version
            </Button>
          </Grid>
          <Grid item xs={12} sm={8}>
            Synkronisera abonnemang från Stripe
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={syncronizeStripeProducts}
              variant="contained"
              sx={{ width: 300, mb: 2 }}
              disabled={syncInProgress}
            >
              Synkronisera
            </Button>
          </Grid>
          <Grid item xs={12} sm={8}>
            Möjlighet att redigera information om positioner
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link to="positions">Redigera positioner</Link>
          </Grid>
        </Grid>
      ) : (
        <Typography>Not Authorized</Typography>
      )}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
      />
    </StdView>
  );
};

export default AdminView;
