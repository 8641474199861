import { Alert, Box, Stack, TextField } from "@mui/material";
import {
  authStateSelector,
  currentUser,
  registerAsync,
} from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocales } from "locales";
import type { RegisterRequest } from "api";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import PasswordField from "./PasswordField";
import isEmailValidator from "validator/lib/isEmail";
import { useNavigate } from "react-router-dom";
import { registerUserAsync } from "store/slices/payment2Slice";




interface RegisterFormProps {
  onUserCreated(): void;
}
export default function RegisterForm({ onUserCreated }: RegisterFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, currentLang } = useLocales();
  const { isAuthenticating, hasRegError, regError } =
    useSelector(authStateSelector);

    const validationSchema = yup.object({
      firstName: yup
        .string()
        .matches(/^(?!soulwise\b)/i, t("reservedWord"))
        .required(t("firstNameMandatory")),
      lastName: yup
        .string()
        .matches(/^(?!soulwise\b)/i, t("reservedWord"))
        .required(t("lastNameMandatory")),
      email: yup
        .string()
        .email(t("enterValidEmail"))
        .required(t("emailMandatory"))
        .test("is-valid", t("enterValidEmail"), (value) =>
          value
            ? isEmailValidator(value)
            : new yup.ValidationError(t("enterValidEmail"))
        ),
      emailConfirm: yup
        .string()
        .test("email", t("notEqualEmail"), function (emailConfirmation) {
          return emailConfirmation === this.parent.email;
        }),
      password: yup
        .string()
        .required(t("notValidPassword"))
        .matches(
          /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          t("passwordRule")
        ),
    });

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{width:"100%", maxWidth:"400px"}}>
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          emailConfirm: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          try {
            var request = {
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.password,
              email: values.email,
              language: currentLang.value
            } as RegisterRequest;
            const result = await dispatch<any>(registerUserAsync(request));
            if (result) {
              onUserCreated();
            }
          } catch (error) {
            console.log(error);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, errors, handleChange, touched }) => (
          <Form noValidate autoComplete="off">
            <Stack spacing={2}>
              {hasRegError && (
                <Alert severity="error" variant="outlined">
                  {regError}
                </Alert>
              )}
              <TextField
                sx={{ width: { xs: "100%", sm: "auto" } }}
                required
                id="firstName"
                name="firstName"
                label={t("firstName")}
                variant="outlined"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                fullWidth
                size="small"
              />

              <TextField
                sx={{ width: { xs: "100%", sm: "auto" } }}
                required
                id="lastName"
                name="lastName"
                label={t("lastName")}
                variant="outlined"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                size="small"
              />
              <TextField
                sx={{ width: { xs: "100%", sm: "auto" } }}
                required
                id="email"
                name="email"
                label={t("email")}
                variant="outlined"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                size="small"
              />
              <TextField
                sx={{ width: { xs: "100%", sm: "auto" } }}
                required
                id="emailConfirm"
                name="emailConfirm"
                label={t("confirmEmail")}
                variant="outlined"
                value={values.emailConfirm}
                onChange={handleChange}
                error={touched.emailConfirm && Boolean(errors.emailConfirm)}
                helperText={touched.emailConfirm && errors.emailConfirm}
                size="small"
              />
              <PasswordField
                required
                fullWidth
                name="password"
                label={t("password")}
                id="password"
                disabled={isAuthenticating}
                autoComplete="current-password"
                size="small"
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isAuthenticating}
                disabled={isSubmitting}
              >
                {t("register")}
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
      </Box>
    </Box>
  );
}
