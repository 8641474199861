import { Card, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  checkPaymentStatusAsync,
  payment2StateSelector,
  setActiveStep,
} from "store/slices/payment2Slice";
import RichTextField from "../RichTextField";
import {
  contentfulStateSelector,
  getThanksForSubscriptionText,
} from "store/slices/contentfulSlice";
import { useLocales } from "locales";
import { PaymentStatus } from "types/Subscription";

interface PaymentSummaryProps {}

const PaymentSummary = ({}: PaymentSummaryProps) => {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const { currentLang } = useLocales();

  const { thanksForSubscriptionText } = useSelector(contentfulStateSelector);
  const { paymentSession, sessionId } = useSelector(payment2StateSelector);

  useEffect(() => {
    dispatch<any>(getThanksForSubscriptionText(currentLang.value));
  }, [dispatch, sessionId, currentLang]);

  return (
    <Container maxWidth={"lg"} sx={{ p: 0 }}>
      <Card>
        <Stack sx={{ p: "20px" }}>
          <Typography variant="h2" gutterBottom>
            {thanksForSubscriptionText?.title}
          </Typography>
          {thanksForSubscriptionText && (
            <RichTextField document={thanksForSubscriptionText?.text.json!} />
          )}
        </Stack>
      </Card>
    </Container>
  );
};

export default PaymentSummary;
