/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PositionDto } from '../models/PositionDto';
import type { SearchPositions } from '../models/SearchPositions';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PositionService {

    /**
     * @returns PositionDto OK
     * @throws ApiError
     */
    public static getApiPositions(): CancelablePromise<Array<PositionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/positions',
        });
    }

    /**
     * @param positionId 
     * @returns PositionDto OK
     * @throws ApiError
     */
    public static getApiPositions1(
positionId: number,
): CancelablePromise<PositionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/positions/{positionId}',
            path: {
                'positionId': positionId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns PositionDto OK
     * @throws ApiError
     */
    public static postApiPositionsSearch(
requestBody?: SearchPositions,
): CancelablePromise<Array<PositionDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/positions/search',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns PositionDto OK
     * @throws ApiError
     */
    public static putApiPositionsUpdate(
requestBody?: PositionDto,
): CancelablePromise<PositionDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/positions/Update',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

}
