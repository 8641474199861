import React, { useEffect, useMemo } from "react";
import { LoginForm } from "components/forms/LoginForm";
import { authStateSelector } from "store/slices/authSlice";
import { useSelector } from "react-redux";
import {
  Link as RouterLink,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Alert, Box, Link, Typography } from "@mui/material";
import AuthCard from "components/cards/AuthCard";
import { useLocales } from "locales";
import LanguagePopover from "components/language/language-popover";

function useRedirectQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const AuthView = (): JSX.Element => {
  const authState = useSelector(authStateSelector);
  const { t } = useLocales();
  const locales = useLocales();
  const [searchParams] = useSearchParams();
  const redirectQuery = useRedirectQuery();

  useEffect(() => {
    const lang = searchParams.get("lang");
    if (lang !== null) locales.onChangeLang(lang);
  }, [searchParams]);

  if (authState.isAuthenticated && !authState.isAuthenticating)
    return <Navigate to={redirectQuery.get("redirect") ?? "/"} />;

  return (
    <AuthCard
      title={t("homeTitle")}
      form={
        <Box
          sx={{
            mb: 3,
          }}
        >
          {authState.hasError && (
            <Alert severity="error" variant="outlined">
              {authState.error}
            </Alert>
          )}
          <LoginForm />
        </Box>
      }
      footer={
        <Box>
          <Typography textAlign={"center"} gutterBottom>
            {t("newHere")}{" "}
            <Link component={RouterLink} to="/register">
              {t("createAccount")}
            </Link>
          </Typography>
          <Typography textAlign={"center"} gutterBottom>
            {t("forgotPassword")}{" "}
            <Link component={RouterLink} to="/forgotpassword">
              {t("clickHere")}
            </Link>
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "15px" }}>
            <LanguagePopover color="black" />
          </Box>
        </Box>
      }
    />
  );
};
