import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import { Box } from "@mui/system";
import { initPaymentAsync, payment2StateSelector } from "store/slices/payment2Slice";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

interface PaymentFormProps {
  type: "register" | "subscription"
}

const Payment2Form = ({type}: PaymentFormProps) => {
  const dispatch = useDispatch<any>();
  
  const { currentUser } = useSelector(authStateSelector);
  const { clientSecret } = useSelector(payment2StateSelector);

  console.log(currentUser);
  console.log(type);

  useEffect(() => {
    dispatch(initPaymentAsync(currentUser?.id!))
  }, []);

  console.log(clientSecret);

  return (
    <Box sx={{ backgroundColor: "transparent" }}>
      {clientSecret && (
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_API_KEY!}
          client-reference-id={`${currentUser?.id}-${type}`}
          // customer-email={currentUser?.email!}
          customer-session-client-secret={clientSecret}
        ></stripe-pricing-table>
      )}
    </Box>
  );
};

export default Payment2Form
