import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AccountService,
  ApiError,
  OpenAPI,
  PaymentService,
  ProductDto,
  RegisterRequest,
  SessionDto,
  UserDto,
  UsersService,
} from "api";
import { AppThunk, RootState } from "store";
import { ApiErrorBody } from "utils/model";
import { currentUser, addSubscription, authenticated } from "./authSlice";
import { getApiError } from "utils/apiUtils";

export enum StepType {
  CreateAccount = 0,
  Pay = 1,
  Summary = 2,
}

export interface Payment2State {
  user: UserDto | null;
  products: ProductDto[];
  activeStep: number;
  sessionId: string;
  clientSecret: string;
  paymentSession: SessionDto | null;
  selectedPriceId: number;
  loading: boolean;
  hasError: boolean;
  error?: string;
}

const initialState: Payment2State = {
  user: null,
  products: [],
  activeStep: 0,
  sessionId: "",
  clientSecret: "",
  paymentSession: null,
  selectedPriceId: 0,
  loading: false,
  hasError: false,
  error: "",
};

export const payment2Slice = createSlice({
  name: "payment2",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    clearError: (state) => {
      state.hasError = false;
      state.error = "";
    },
    updateProducts: (state, action: PayloadAction<ProductDto[]>) => {
      state.products = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setPaymentSession: (state, action: PayloadAction<SessionDto>) => {
      state.paymentSession = action.payload;
    },
    setUser: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
    },
    setPriceId: (state, action: PayloadAction<number>) => {
      state.selectedPriceId = action.payload;
    },
    setClientSecret: (state, action: PayloadAction<string>) => {
      state.clientSecret = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    initPayment: (state) => {
      state.hasError = false;
      state.error = "";
      state.sessionId = "";
      state.paymentSession = null;
    },
  },
});

export const {
  hasError,
  clearError,
  loading,
  updateProducts,
  setUser,
  setPriceId,
  setSessionId,
  setPaymentSession,
  setClientSecret,
  setActiveStep,
  initPayment,
} = payment2Slice.actions;

export const loadProductsAsync = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loading(true));
    const products = await PaymentService.getApiPaymentProducts();
    dispatch(updateProducts(products));
  } catch (e) {
    var error = e as ApiError;
    var text = error.body ? (error.body as ApiErrorBody).error : error.message;
    dispatch(hasError(text!));
  } finally {
    dispatch(loading(false));
  }
};

export const cancelSubscriptionAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearError());
    const result = await UsersService.putApiUsersCancelSubscription();
    dispatch(currentUser(result));
  } catch (e) {
    dispatch(hasError(getApiError(e).message));
  }
};

export const resumeSubscriptionAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearError());
    const result = await UsersService.putApiUsersResumeSubscription();
    dispatch(currentUser(result));
  } catch (e) {
    dispatch(hasError(getApiError(e).message));
  }
};

export const checkPaymentStatusAsync =
  (sessionId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(clearError());

      const session = await PaymentService.getApiPaymentCheckSessionStatus(
        sessionId
      );
      dispatch(setPaymentSession(session));
      // if (session.subscription) dispatch(addSubscription(session.subscription));
    } catch (e) {
      dispatch(hasError(getApiError(e).message));
    } finally {
    }
  };

export const initPaymentAsync =
  (userId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(initPayment());

      const response =
        await PaymentService.postApiPaymentCreateCheckoutSession1(userId);
      dispatch(setClientSecret(response.clientSecret!));
    } catch (e) {
      dispatch(hasError(getApiError(e).message));
    } finally {
    }
  };

export const registerUserAsync =
  (user: RegisterRequest): AppThunk =>
  async (dispatch) => {
    try {
      const response = await AccountService.postApiAccountRegister(user);
      OpenAPI.TOKEN = response.token ?? "";
      dispatch(authenticated(response));
      const personResponse = await AccountService.getApiAccountCurrent();
      dispatch(currentUser(personResponse));
      return true;
    } catch (e) {
      dispatch(hasError(getApiError(e).message));
      return false;
    } finally {
    }
  };

export const payment2StateSelector = (state: RootState): Payment2State =>
  state.payment2;

export default payment2Slice.reducer;
