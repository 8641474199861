import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

export type PrivateRouteProps = {
  isAuthenticated: boolean
  authenticationPath: string
  outlet: JSX.Element
}

export default function PrivateRoute({
  isAuthenticated,
  authenticationPath,
  outlet,
}: PrivateRouteProps) {
  const location = useLocation()
  const [searchParams] = useSearchParams();
  const uri = encodeURIComponent(`${location.pathname}${location.search}`)
  const lang = searchParams.get("lang") ?? 'sv';

  if (isAuthenticated) {
    return outlet
  } else {
    return (
      <Navigate
        to={{
          pathname: authenticationPath,
          search: `?redirect=${uri}` + `&lang=${lang}`,
        }}
      />
    )
  }
}
